@import "mixins/banner";

@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
//@import "tables";
//@import "forms";
@import "buttons";
@import "transitions";
//@import "dropdown";
//@import "button-group";
@import "nav";
@import "navbar";
@import "card";
//@import "accordion";
//@import "breadcrumb";
@import "pagination";
//@import "badge";
//@import "alert";
//@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
//@import "tooltip";
//@import "popover";
@import "carousel";
//@import "spinners";
//@import "offcanvas";
//@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

html {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, sans-serif;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 400;
  color: $gv-blue-dark;
}

h3 {
  font-size: 1.8rem;
  color: $gv-gray-dark;
}

h5,
.h5{
  font-size: 1.5rem;
}

.bg-body-tertiary {
  background-color: $white;
}

.arrownews{
  font-size: 2.8rem;
  color: $gv-blue-dark;
}

.arrowpartners{
  font-size: 2.8rem;
  color: $gv-gray-dark;
}

.gvproperties {
  text-decoration: none;

  h3 {
    font-weight: 200;
    color: $gv-gray-regular;
  }
}

.contactfooter{
  font-size: 1.2rem;
  .nav-link{
    text-decoration: underline;
  }
}

.gvbutton {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  @include border-top-start-radius(30px);
  @include border-top-end-radius(30px);
  @include border-bottom-end-radius(30px);
  @include border-bottom-start-radius(30px);
}

.newsitem{
  min-height: 420px;
}

p{
  font-size: 1.3rem;
}

.gvblue,
.gvcomments {
  background-color: rgba(113, 195, 235, .2);

  h2 {
    color: $gv-blue-regular;
  }

  h3 {
    a {
      color: $gv-gray-regular;
    }
  }

  .carousel-control-prev {
    left: -150px;
  }

  .carousel-control-next {
    right: -150px;
  }
}

.partnercarousel {
  .carousel-control-prev {
    left: -150px;
  }

  .carousel-control-prev-icon {
    color: $gv-blue-dark;
  }

  .carousel-control-next {
    right: -150px;
  }

  .carousel-control-next-icon {
    color: $gv-blue-dark;
  }
}

.navbar-expand-lg .navbar-nav {
  .nav-link {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #4e5055;
    text-transform: uppercase;
  }

  .nav-item:hover,
  .active{
    list-style: outside none;
    border-bottom: solid .2rem $primary;

    .nav-link {
      color: $primary;
    }
  }
}

.bannercard {
  border: 0;
}

.slogan{
  font-weight: 700;
  color: $gv-blue-dark;
}

.slogansub{
  font-weight: 400;
  color: $gv-blue-dark;
}

.bannercontent {
  padding-top: 5%;
}

.lead {
  // margin-top: 1.5rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
}

.partnerlead{
  font-size: 1.3rem;
}

.partnerlink{
  padding-bottom: 1.5rem;
  font-size: 1.5rem;
}

.btn-outline-info{
  font-size: 1.36rem;
  font-weight: 400;

  &:hover{
    color: $white;
  }
}

.gvsubmenu{
  width: 100%;

  .gvsumenuholder{
    padding: 0;
    background-color: $gv-blue-dark;

    .gvclose{
      padding: 2rem;
      font-size: 2rem;
    }

    .nav-link{
      display: block;
      padding: 2rem;
      font-size: 2rem;
      color: $white;

      &:hover{
        background-color: $gv-blue-regular;
      }
    }
  }

  .gvsearchform{
    font-size: 3rem;
  }
}

.gvpropertiesdesc{
  font-size: 2rem;
  font-weight: 300;
}

.gvsubelements{
  .active{
    font-weight: 600;
    color: $gv-gray-dark;
  }


  a{
    padding: 3rem;
    font-size: 1.2rem;
    color: $gv-gray-dark;

    &:hover{
      color: $gv-gray-dark;
    }
  }
}

.blog-post{
  font-size: 1.4rem;

  h1{
    font-size: 2.5rem;
    color: $gv-blue-dark;
  }
  .blog-post-meta{
    font-size: 1rem;
    font-weight: 600;
  }
}

.contact{
  h3{
    color: $gv-blue-dark;
  }
}

@include media-breakpoint-down(sm) {
  .gvmobilelogo{
    float: left;
    width: 75%;
  }

  .gvmobileicon{
    float: right;
  }

  html {
    font-size: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Lato, sans-serif;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    color: $gv-blue-dark;
  }

  h3 {
    font-size: 1.6rem;
    color: $gv-gray-dark;
  }

  .bg-body-tertiary {
    background-color: $white;
  }

  .slogan{
    font-weight: 400;
    color: $gv-blue-dark;
  }

  .gvproperties {
    text-decoration: none;

    h3 {
      font-weight: 200;
      color: $gv-gray-regular;
    }
  }

  .gvbutton {
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    @include border-top-start-radius(30px);
    @include border-top-end-radius(30px);
    @include border-bottom-end-radius(30px);
    @include border-bottom-start-radius(30px);
  }

  .newsitem{
    margin-bottom: 3rem;
  }

  p{
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .gvblue,
  .gvcomments {
    background-color: rgba(113, 195, 235, .2);

    h2 {
      color: $gv-blue-dark;
    }

    h3 {
      padding: 1rem 0;
      a {
        color: $gv-blue-dark;
      }
    }

    .carousel-control-prev {
      left: -150px;
    }

    .carousel-control-next {
      right: -150px;
    }
  }

  .partnercarousel {
    .carousel-inner{
      width: 80%;
      margin: 0 auto;
    }
    .carousel-control-prev {
      left: 0;

      i{
        font-size: 2rem;
      }
    }

    .carousel-control-prev-icon {
      color: $gv-blue-dark;
    }

    .carousel-control-next {
      right: 0;

      i{
        font-size: 2rem;
      }

    }

    .carousel-control-next-icon {
      color: $gv-blue-dark;
    }
  }

  .navbar-expand-lg .navbar-nav {
    .nav-link {
      padding: 1rem 2rem;
      font-size: 1.2rem;
      font-weight: 600;
      color: #4e5055;
      text-transform: uppercase;
    }

    .nav-item:hover,
    .active{
      list-style: outside none;
      border-bottom: solid .2rem $primary;

      .nav-link {
        color: $primary;
      }
    }
  }

  .bannercard {
    border: 0;
  }

  .bannercontent {
    padding-top: 5%;
  }

  .lead {
    // margin-top: 2rem;
    font-size: 1.7rem;
    line-height: 2.2rem;
  }

  .partnerlead{
    font-size: 1.5rem;
  }

  .btn-outline-info{
    font-size: 1.5rem;
    font-weight: 400;

    &:hover{
      color: $white;
    }
  }

  .gvsubmenu{
    width: 100%;

    .gvsumenuholder{
      padding: 0;
      background-color: $gv-blue-dark;

      .gvclose{
        padding: 2rem;
        font-size: 2rem;
      }

      .nav-link{
        display: block;
        padding: 2rem;
        font-size: 2rem;
        color: $white;

        &:hover{
          background-color: $gv-blue-regular;
        }
      }
    }

    .gvsearchform{
      font-size: 3rem;
    }
  }

  .gvpropertiesdesc{
    font-size: 1.7rem;
    font-weight: 300;
  }

  .gvsubelements{
    .active{
      font-weight: 600;
      color: $gv-gray-dark;
    }

    a{
      padding: 2rem;
      font-size: 1rem;
      color: $gv-gray-dark;

      &:hover{
        color: $gv-gray-dark;
      }
    }
  }

  .blog-post{
    font-size: 1.4rem;

    .blog-post-meta{
      font-size: 1rem;
      font-weight: 600;
    }
  }

}

@include media-breakpoint-only(sm) {
  .gvsubmenu{
    top: 0;
    height: 100vh;
    background-color: $gv-blue-dark;
  }
}

@include media-breakpoint-only(md) {
  .gvsubmenu{
    top: 0;
    height: 100vh;
  }
}
